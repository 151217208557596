<template>
  <div>
    <div class="box box_mm">
      <div class="box_main">
        <div
          style="
            height: 75vh;
            overflow-y: auto;
            padding-right: 0.5rem;
            padding-bottom: 40px;
            box-sizing: border-box;
          "
        >
          <el-form
            :model="addnewinfo"
            :rules="addnewinfo_rules"
            ref="addnewinfo"
            label-width="100px"
            label-position="left"
            class="demo-ruleForm"
            style="padding-left: 20px"
          >
            <el-form-item
              label="上传附件"
              required
              style="display: flex; align-items: flex-start"
            >
              <div class="linkCss" @click="PreviewFile(fj_url)">
                {{ fj_name }}
              </div>
            </el-form-item>
            <!-- <el-form-item label="是否拟办" required style="display: flex;align-items: center;">
                                <el-select v-model="addnewinfo.sflb" placeholder="请选择">
                                    <el-option label="是" :value="1"></el-option>
                                    <el-option label="否" :value="2"></el-option>
                                  </el-select>
                            </el-form-item> -->
            <el-form-item
              label="文单"
              prop="lwdw"
              style="display: flex; align-items: flex-start"
            >
              <div class="mytabble" style="width: 60vw">
                <div class="innerbox">
                  <div class="title">城北区人民检察院公文处理单</div>
                  <div class="tablebox">
                    <div class="line">
                      <div class="namebox">
                        <span>收文编号</span>
                      </div>
                      <div class="value" style="width: 10%">
                        <el-input
                          v-model="addnewinfo.swzh"
                          type="number"
                        ></el-input>
                      </div>
                      <div class="namebox">收文日期</div>
                      <div class="value" style="width: 18%; border-right: none">
                        <el-date-picker
                          v-model="addnewinfo.swrq"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="选择日期"
                        >
                        </el-date-picker>
                      </div>
                      <div class="namebox">密级</div>
                      <div class="value" style="width: 18%; border-right: none">
                        <el-select v-model="addnewinfo.mj" placeholder="请选择">
                          <el-option label="无" value="无"></el-option>
                          <el-option label="机密" value="机密"></el-option>
                          <el-option label="秘密" value="秘密"></el-option>
                          <el-option
                            label="最高机密"
                            value="最高机密"
                          ></el-option>
                        </el-select>
                      </div>
                      <div class="namebox">存档意见</div>
                      <div class="value" style="width: 18%">
                        <el-input v-model="addnewinfo.cdyj"></el-input>
                      </div>
                    </div>
                    <div class="line">
                      <div class="namebox">
                        <span>来文单位</span>
                      </div>
                      <div class="value" style="width: 37%">
                        <el-input
                          v-model="addnewinfo.lwdw"
                          placeholder="请输入"
                        ></el-input>
                      </div>
                      <div class="namebox">
                        <span>来文号</span>
                      </div>
                      <div class="value" style="width: 45%">
                        <el-input
                          v-model="addnewinfo.lwzh"
                          placeholder="请输入"
                        ></el-input>
                      </div>
                    </div>
                    <div class="line" style="height: 100px">
                      <div class="namebox">
                        <span>文件标题</span>
                      </div>
                      <div
                        class="value"
                        style="width: 91%; border-right: 1px solid #333"
                      >
                        <el-input
                          v-model="addnewinfo.name"
                          placeholder="请输入文件标题"
                        ></el-input>
                      </div>
                    </div>
                    <div class="line" style="height: 70px">
                      <div class="namebox fdc">
                        <span class="h18">办公室</span>
                        <span class="h18">拟办意见</span>
                      </div>
                      <div
                        class="value jsfa aifs"
                        style="
                          width: 91%;
                          border-right: 1px solid #333;
                          line-height: 18px;
                        "
                      >
                        {{ info.nb && info.nb.yijian }}
                      </div>
                    </div>
                    <div class="line" style="height: 200px">
                      <div class="namebox fdc">
                        <span class="h18">分管领</span>
                        <span class="h18">导意见</span>
                      </div>
                      <div
                        class="value jsfa aifs"
                        style="width: 37%; line-height: 18px"
                      >
                        {{ info.yj && info.yj.yijian }}
                      </div>
                      <div class="namebox fdc">
                        <span class="h18">检察长</span>
                        <span class="h18" style="margin-top: 14px">批示</span>
                      </div>
                      <div
                        class="value jsfa aifs"
                        style="width: 45%; line-height: 18px"
                      >
                        {{ info.ps && info.ps.yijian }}
                      </div>
                    </div>
                    <div class="line" style="height: 335px">
                      <div class="namebox fdc">
                        <span class="h18">院领导</span>
                        <span class="h18">意见</span>
                      </div>
                      <div
                        class="value fdc pd0 jsfa"
                        style="width: 91%; border-right: 1px solid #333"
                      >
                        <div
                          class="value2 jsfa aifs"
                          style="height: 67px; line-height: 18px"
                        >
                          {{ info.yld && info.yld[0] && info.yld[0].yijian }}
                        </div>
                        <div
                          class="value2 jsfa aifs"
                          style="
                            border-bottom: 1px solid #333;
                            height: 67px;
                            line-height: 18px;
                          "
                        >
                          {{ info.yld && info.yld[1] && info.yld[1].yijian }}
                        </div>
                        <div
                          class="value2 jsfa aifs"
                          style="height: 67px; line-height: 18px"
                        >
                          {{ info.yld && info.yld[2] && info.yld[2].yijian }}
                        </div>
                        <div
                          class="value2 jsfa aifs"
                          style="height: 67px; line-height: 18px"
                        >
                          {{ info.yld && info.yld[3] && info.yld[3].yijian }}
                        </div>
                        <div
                          class="value2 jsfa aifs"
                          style="
                            height: 67px;
                            border-bottom: none;
                            line-height: 18px;
                          "
                        >
                          {{ info.yld && info.yld[4] && info.yld[4].yijian }}
                        </div>
                      </div>
                    </div>
                    <div class="line" style="height: 130px">
                      <div class="namebox fdc">
                        <span>传</span>
                        <span>阅</span>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span class="h18">第一检</span>
                          <span class="h18">察部</span>
                        </div>
                        <div class="value2" style="height: 80px">
                          {{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 41 && item.state == 2;
                            })
                              ? "已阅"
                              : ""
                          }}
                        </div>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span class="h18">第二检</span>
                          <span class="h18">察部</span>
                        </div>
                        <div class="value2" style="height: 80px">
                          {{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 42 && item.state == 2;
                            })
                              ? "已阅"
                              : ""
                          }}
                        </div>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span class="h18">第三检</span>
                          <span class="h18">察部</span>
                        </div>
                        <div class="value2" style="height: 80px">
                          {{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 43 && item.state == 2;
                            })
                              ? "已阅"
                              : ""
                          }}
                        </div>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span class="h18">第四检</span>
                          <span class="h18">察部</span>
                        </div>
                        <div class="value2" style="height: 80px">
                          {{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 44 && item.state == 2;
                            })
                              ? "已阅"
                              : ""
                          }}
                        </div>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span class="h18">第五检</span>
                          <span class="h18">察部</span>
                        </div>
                        <div class="value2" style="height: 80px">
                          {{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 45 && item.state == 2;
                            })
                              ? "已阅"
                              : ""
                          }}
                        </div>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span class="h18">综合业</span>
                          <span class="h18">务部</span>
                        </div>
                        <div class="value2" style="height: 80px">
                          {{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 46 && item.state == 2;
                            })
                              ? "已阅"
                              : ""
                          }}
                        </div>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span>政治部</span>
                        </div>
                        <div class="value2" style="height: 80px">
                          {{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 47 && item.state == 2;
                            })
                              ? "已阅"
                              : ""
                          }}
                        </div>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span>办公室</span>
                        </div>
                        <div class="value2" style="height: 80px">
                          {{
                            info.cy &&
                            info.cy.find((item) => {
                              return item.useradmin == 40 && item.state == 2;
                            })
                              ? "已阅"
                              : ""
                          }}
                        </div>
                      </div>
                      <div
                        class="value fdc jsfa pd0"
                        style="width: 9.11%; border-right: none"
                      >
                        <div class="value2" style="height: 50px">
                          <span></span>
                        </div>
                        <div class="value2" style="height: 80px">
                          <span></span>
                        </div>
                      </div>
                      <div class="value fdc jsfa pd0" style="width: 9.11%">
                        <div class="value2" style="height: 50px">
                          <span></span>
                        </div>
                        <div class="value2" style="height: 80px">
                          <span></span>
                        </div>
                      </div>
                    </div>
                    <div class="line" style="height: 90px">
                      <div class="namebox fdc">
                        <span class="h18">承办</span>
                        <span class="h18">结果</span>
                      </div>
                      <div
                        class="value jsfa aifs"
                        style="
                          width: 91%;
                          border-right: 1px solid #333;
                          line-height: 18px;
                        "
                      >
                        {{ info.blr[0] && info.blr[0].yijian }}
                      </div>
                    </div>
                    <div class="line" style="height: 70px">
                      <div class="namebox">
                        <span>备注</span>
                      </div>
                      <div
                        class="value fdc"
                        style="
                          width: 91%;
                          border-right: 1px solid #333;
                          justify-content: flex-start;
                        "
                      >
                        <span class="tal h18" style=""
                          >公文办理完毕后务必及时退回机要室，承办部门或者个人不得私自留存。</span
                        >
                        <span class="tal h18"
                          >承办部门或者个人办理涉密公文，不得私自复印、留存、扩大知秘范围，</span
                        >
                        <span class="tal h18"
                          >注:我院机要文件多为黑白文头，此为原件，请勿混淆。</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item
              label="紧急程度"
              required
              style="display: flex; align-items: center"
            >
              {{ addnewinfo.jjcd }}
            </el-form-item>
          </el-form>
        </div>

        <div style="display: flex; align-content: center; margin-top: 30px">
          <el-button @click="setaddnew">取 消</el-button>
          <el-button type="success" @click="submitForm()">已 阅</el-button>
        </div>
      </div>
    </div>

    <el-dialog
      width="70%"
      title="预览"
      :visible.sync="innerVisible"
      append-to-body
    >
      <viewFile @close="onClose" @open="onOpen" :src="previewSrc" />
    </el-dialog>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import selectuser from "@/components/select_user.vue";
import viewFile from "@/components/viewFile.vue";
export default {
  components: {
    selectuser,
    Editor,
    Toolbar,
    viewFile,
  },
  data() {
    return {
      baseurl: "",
      addnewinfo: {
        swzh: "",
        id: "",
        swrq: "",
        mj: "公开",
        cdyj: "",
        lwdw: "",
        lwzh: "",
        name: "",
        jjcd: "平急",
        fj: "",
        blruser_id: "", //承办人
        ylduser_id: "", //院领导
        cyuser_id: "", //传阅
      },
      fj_url: "",
      fj_name: "",
      addnewinfo_rules: {
        lwdw: [
          {
            required: true,
            message: "请录入信息",
            trigger: "blur",
          },
        ],
      },

      info: {},
      innerVisible: false,
      previewSrc: "",
    };
  },
  created() {
    this.baseurl = this.$URL;
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$post({
        url: "/api/matter/details",
        params: {
          id: this.$route.query.id,
        },
      }).then((res) => {
        for (let key in this.addnewinfo) {
          this.addnewinfo[key] = res[key];
        }
        this.info = res;
        this.fj_url = res.fj_url;
        this.fj_name = res.filename;
      });
    },
    preview(file) {
      window.open(file.url, "_blank");
    },
    PreviewFile(url) {
      window.open(url, "_blank");
      return;
      this.innerVisible = true;
      this.previewSrc = url;
    },
    onClose() {},
    onOpen() {},
    submitForm() {
      if (this.$route.query.cytype == 1) {
        this.$confirm("是否确认已阅", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$post({
              url: "/api/matter/cy",
              params: {
                id: this.$route.query.id,
                yijian: "",
              },
            }).then((res) => {
              this.$message.success("操作成功");
              this.$router.back();
            });
          })
          .catch(() => {
            this.$message.info("已取消");
          });
        return;
      }
      this.$prompt("请输入意见", "传阅提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: "输入内容不能为空",
      })
        .then(({ value }) => {
          // 用户确认时，value是输入的内容
          this.$post({
            url: "/api/matter/cy",
            params: {
              id: this.$route.query.id,
              yijian: value,
            },
          }).then((res) => {
            this.$message.success("操作成功");
            this.$router.back();
          });
        })
        .catch(() => {
          // 用户取消或关闭对话框
          this.$message.info("已取消");
        });
      return;
    },
    setaddnew() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.box_main {
  background-color: #fff;
  padding: 15px 55px;
  width: 100%;
  box-sizing: border-box;
  min-height: 100%;
}

.search_box {
  display: flex;
  align-items: center;
  font-size: 0.16rem;

  .search_item {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }
}

.box_cont {
  margin-top: 20px;
}

.btn_list {
  display: flex;
  align-items: center;
}

.tc_title {
  font-size: 0.18rem;
  font-weight: bold;
  color: #333;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}

.tc_title:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 4px;
  left: 0px;
  background-color: rgb(26, 144, 253);
}

::v-deep td {
  text-align: center !important;
}

::v-deep .el-table th.el-table__cell > .cell {
  text-align: center !important;
}

::v-deep .el-table th.el-table__cell.is-leaf {
  background-color: rgb(242, 242, 242);
  color: #777;
}

::v-deep .el-table th.el-table__cell.is-leaf,
.el-table td.el-table__cell {
  border-bottom: none !important;
}

::v-deep .el-table td.el-table__cell {
  border-bottom: none !important;
}

::v-deep .el-table {
  margin-top: 10px;
  border-left: none !important;
}

::v-deep .el-table__fixed {
  border-bottom: none;
}

::v-deep .el-table::before {
  background-color: #fff !important;
}

::v-deep .el-table__fixed::before {
  background-color: #fff !important;
}

::v-deep .el-dialog__header {
  display: flex !important;
}

::v-deep .el-dialog__footer span {
  display: flex !important;
  justify-content: space-around;
  width: 100%;
}

::v-deep .el-form-item__content {
  display: flex;
  flex-direction: column;
}

::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

::v-deep .el-table__fixed-right::before {
  background-color: #fff !important;
}

.item_class {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  .el-form-item {
    margin-right: 150px;
  }
}

::v-deep.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.innerbox {
  width: 100%;
  background-color: #fff;
  padding: 20px 10px;
}
.title {
  font-size: 22px;
  color: #333;
  width: 100%;
  text-align: center;
}
.line {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
}
.namebox {
  height: 100%;
  width: 9%;
  border: 1px solid #333;
  padding: 7px;
  text-align: center;
  border-right: none;
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    width: 100%;
  }
}
.namebox:nth-last-child(1) {
  border-right: 1px solid #333;
}
.value {
  height: 100%;
  width: 35%;
  padding: 7px;
  text-align: center;
  border: 1px solid #333;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: none;
  span {
    width: 100%;
  }
}
.value:nth-child(2) {
  border-right: none;
}
.line:nth-last-child(1) {
  border-bottom: 1px solid #333;
}
.btm_btn {
  position: fixed;
  bottom: 0;
  width: 100vw;
  left: 0;
  padding: 10px 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tablebox {
  margin-top: 15px;
}

::v-deep .mytabble .el-input__inner {
  border: none;
}

.value2 {
  width: 100%;
  border-bottom: 1px solid #333;
  padding: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.value2:nth-child(2) {
  border-bottom: none;
}

.fdc {
  flex-direction: column;
}
.jsfa {
  justify-content: flex-start;
}
.aifs {
  align-items: flex-start;
}
.pd0 {
  padding: 0;
}
.tal {
  text-align: left;
}
.h18 {
  height: 18px;
  line-height: 18px;
}
.linkCss {
  color: #409eff;
  cursor: pointer;
}
</style>
